import CuestionariosModule from './CuestionariosModule';
import roles from '@/utils/roles';

const moduleRoute = {
    path: '/cuestionarios',
    component: CuestionariosModule,
    children: [{
        path: '/',
        name: 'cuestionarios',
        meta: { authorize: [roles.doctor] },
        component: () =>
            import ('./views/CuestionariosView.vue')
    },
    {
        path: 'crear',
        name: 'cuestionario-paciente',
        meta: { authorize: [roles.doctor] },
        component: () =>
            import ('./views/CuestionarioPacienteView.vue')
    },
    {
        path: 'crear/:id',
        name: 'cuestionarip-paciente-id',
        meta: { authorize: [roles.doctor, roles.admin] },
        component: () =>
            import ('./views/CuestionarioPacienteView.vue')
    },
 ]
};

export default router => {
    router.addRoute(moduleRoute);
}