const amplifyStore = {
    state: {
        user: {},
    },
    mutations: {
        setAmplifyUser(state, user) {
            state.user = user
            let usuarioSecreto = window.btoa(unescape(encodeURIComponent(JSON.stringify(user))));
            localStorage.setItem("token", usuarioSecreto)
        },
        logOut(state) {
            state.user = {};
            localStorage.removeItem('token');
        }
    },
    actions: {},
    getters: {}
}

export default amplifyStore;