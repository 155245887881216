<template>
  <nav class="nav">
    <v-app-bar color="primary">
      <v-app-bar-nav-icon
        class="nav-menu"
        @click="drawer = true"
        color="white"
      ></v-app-bar-nav-icon>
      <NavLogos />
      <v-spacer></v-spacer>
      <section class="nav-actions">
        <NavUserInfo />
      </section>
    </v-app-bar>
    <NavegationMenu v-model="drawer" />
  </nav>
</template>

<script>
import { NavLogos, NavUserInfo, NavegationMenu } from "../Nav";
export default {
  name: "NavBar",
  components: {
    NavegationMenu,
    NavUserInfo,
    NavLogos,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>

<style scoped>
.nav {
  margin-bottom: 2rem;
}

.nav-actions {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .nav-actions {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .nav-menu {
    display: none;
  }
}
</style>