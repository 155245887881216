import Module from "./Module.vue";
import roles from "@/utils/roles";

const moduleRoute = {
  path: "/admin",
  component: Module,
  children: [
    {
      path: "/",
      name: "admin",
      meta: { authorize: [roles.admin, roles.astrauser] },
      component: () => import("./views/AdminMain.vue"),
    },
    {
        path: "/cuestionario/:id",
        name: "cuestionario",
        meta: { authorize: [roles.admin] },
        component: () => import("./views/CuestionarioView.vue"),
    }
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
