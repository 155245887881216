<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="tertiary">
              <span class="white--text text-h5">{{ avatar }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.nombre }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.nombreConsultorio }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-btn
            elevation="0"
            color="white"
            large
            class="logout-btn"
            @click="logOut"
          >
            <v-icon left> mdi-logout </v-icon>
            Cerrar sesión
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "NavUserInfo",
  data: () => ({
    menu: false,
  }),
  computed: {
    user: {
      get() {
        return this.$store.state.amplifyStore.user;
      },
    },
    avatar() {
      const splitName = this.user.nombre;
      return splitName.length > 2
        ? `${splitName[0][0]}${splitName[1][0]}`
        : `${splitName[0][0]}${splitName[0][1]}`;
    },
  },
  methods: {
    logOut() {
      this.$store.commit("logOut");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.logout-btn {
  width: 100%;
}
</style>