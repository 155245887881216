import Vue from 'vue'
import Vuex from 'vuex'
import amplifyStore from "./amplifyAuth"
import alert from "./alertModule"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    amplifyStore,
    alert
  }
})
