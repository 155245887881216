import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#91275B",
        secondary: "#AC98A2",
        accent: "#F8D693",
        error: "#b71c1c",
        tertiary: "#E6941D",
        background: "#F5F5F6",
      },
    },
  },
});
