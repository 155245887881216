const alert = {
    state: {
        open: false,
        type: 'success',
        message: "here goes the message"
    },
    mutations: {
        openAlert(state, {type, message}) {
            state.open = true;
            state.type = type;
            state.message = message;
        },
        closeAlert(state) {
            state.open = false;
        },
    },
    actions: {},
    getters: {}
}

export default alert;