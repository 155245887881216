import AuthModule from "./AuthModule.vue";

const moduleRoute = {
  path: "/login",
  component: AuthModule,
  children: [
    {
      path: "/",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/create-password",
      name: "create-password",
      component: () => import("./views/CreatePassword.vue"),
      meta: { authorize: true },
    },
    {
      path: "/signup/:id",
      name: "signup",
      component: () => import("./views/CreatePassword.vue"),
    },
    {
      path: "/new-mail",
      name: "new-mail",
      component: () => import("./views/NewMail.vue"),
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
