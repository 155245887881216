import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authenticationModule from './modules/Authentication'
import AdminModule from './modules/Admin'
import CuestionariosModule from "./modules/cuestionarios"
import { registerModules } from './register-modules'
import awsExports from './aws-exports';
import { Amplify } from 'aws-amplify';
import './styles/styles.css'

Amplify.configure(awsExports);


registerModules({
  authentication: authenticationModule,
  adminUsers: AdminModule,
  cuestionarios: CuestionariosModule
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
