import store from '../store/index'

export function openSuccessAlert(message) {
    store.commit('openAlert', { type: 'success', message });
}

export function openErrorAlert(message = "Error inesperado contacte al administrador") {
    store.commit('openAlert', { type: 'error', message });
}

export function closeAlert() {
    store.commit('closeAlert');
}