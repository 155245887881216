<template>
  <div class="logo-background">
    <img
      src="../../assets/logo_1.png"
      class="nav-logo"
      @click="irPantallaPrincipal"
    />
    <img
      src="../../assets/logo_2.png"
      class="nav-logo-words"
      @click="irPantallaPrincipal"
    />
  </div>
</template>

<script>
import roles from "@/utils/roles";
export default {
  name: "NavLogos",
  methods: {
    irPantallaPrincipal() {
      switch (this.userRol) {
        case roles.admin:
          if (this.$route.path !== "/admin") this.$router.push("/admin");
          break;
        case roles.astrauser:
          if (this.$route.path !== "/admin") this.$router.push("/admin");
          break;
        case roles.doctor:
          if (this.$route.path !== "/cuestionarios") this.$router.push("/");
          break;

        default:
          break;
      }
    },
  },
  computed: {
    userRol() {
      return this.$store.state.amplifyStore.user.idRol;
    },
  },
};
</script>

<style scoped>
.logo-background {
  background: #ac98a2;
  padding: 0.3rem 0.9rem;
  display: flex;
  justify-content: space-between;
  border-radius: 26px;
  gap: 1rem;
}
.nav-logo {
  width: 55px;
  height: 35px;
  cursor: pointer;
}
.nav-logo-words {
  height: 35px;
  cursor: pointer;
}

@media only screen and (max-width: 360px) {
  .nav-logo {
    display: none;
  }
}
</style>