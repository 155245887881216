<template>
  <div>
    <v-navigation-drawer v-model="modelValue" absolute temporary>
      <v-list nav dense>
        <v-list-item-group mandatory color="primary">
          <!-- <template v-if="roles.doctor === role">
            <v-list-item
              :value="routesname.studies"
              @click="goTo(routesname.studies)"
            >
              <v-list-item-icon>
                <v-icon>mdi-file-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Estudios</v-list-item-title>
            </v-list-item>

            <v-list-item
              :value="routesname.patients"
              @click="goTo(routesname.patients)"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Pacientes</v-list-item-title>
            </v-list-item>
          </template> -->

          <!-- <v-list-item
            :value="routesname.adminStudies"
            @click="goTo(routesname.adminStudies)"
            v-if="[roles.admin, roles.astrauser].includes(role)"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Estudios</v-list-item-title>
          </v-list-item>

          <v-list-item
            :value="routesname.admin"
            @click="goTo(routesname.admin)"
            v-if="role === roles.admin"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Adminstración</v-list-item-title>
          </v-list-item>-->

          <v-list-item @click="logOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  props: {
    value: {},
  },
  model: {
    prop: "value",
    event: "valuechange",
  },
  computed: {
    option: {
      get() {
        return this.$route.name;
      },
      set(value) {
        return value;
      },
    },
    modelValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("valuechange", value);
      },
    },
  },
  data: () => ({}),
  methods: {
    goTo(page) {
      if (this.option !== page) this.$router.push(`/${page}`);
    },
    async logOut() {
      try {
        await Auth.signOut();
        this.$store.commit("logOut");
        this.$router.push("/login");
      } catch (error) {
        console.log("error signing out: ", error);
      }
      
    },
  },
};
</script>

<style scoped>
.navigation-drawer {
  position: absolute;
}
</style>
