<template>
  <v-app class="app">
    <div class="container-app" v-if="autenticado">
      <Navbar />
      <v-main>
        <Alert />
        <router-view />
      </v-main>
    </div>
    <div v-else>
      <router-view />
    </div>
  </v-app>
</template>

<script>
import Navbar from "./components/layout/Navbar.vue";
import Alert from "./components/alerts/Alert.vue"

export default {
  name: 'App',

  data: () => ({
    token: null
  }),
  computed: {
    autenticado: {
      get() {
        return this.$store.state.amplifyStore.user.id ? true : false;
      }
    }
  },
  created() {
    const usuarioString = localStorage.getItem('token');
    if (usuarioString) {
      let JSONPlano = decodeURIComponent(escape(window.atob(usuarioString)))
      const infoUsuario = JSON.parse(JSONPlano)
      this.$store.commit("setAmplifyUser", infoUsuario);
    } else {
      this.$store.commit('logOut')
    }
  },
  components: {
    Navbar,
    Alert
  }
};
</script>

<style scoped>
.app {
  background-color: #F5F5F6;
}

.container-app {
  padding-bottom: 2.5rem;
}
</style>
