import Vue from "vue";
import VueRouter from "vue-router";
import roles from "../utils/roles";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "cuestionarios",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const userInfo = localStorage.getItem("token");
  const currentUser = userInfo
    ? JSON.parse(decodeURIComponent(escape(window.atob(userInfo))))
    : null;
  if (authorize) {
    if (!currentUser) {
      return next({ path: "/login" });
    }
    if (authorize.length && !authorize.includes(currentUser.idRol)) {
      if (
        currentUser.idRol === roles.admin ||
        currentUser.idRol === roles.astrauser
      )
        return next({ path: "/admin" });
      if (currentUser.idRol === roles.doctor)
        return next({ path: "/cuestionarios" });
    }
  } else if (userInfo && to.name === "login") {
    return next({ path: "/" });
  }
  next();
});

export default router;
